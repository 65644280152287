import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "271",
  height: "280",
  viewBox: "0 0 271 280",
  version: "1.1"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M 11.500 47.417 C 5.399 50.141, 0 58.395, 0 65 C -0 68.997, 2.485 74.851, 5.531 78.032 L 7.956 80.564 8.228 166.532 L 8.500 252.500 11.419 258.446 C 23.110 282.257, 55.059 286.939, 72.869 267.452 C 75.674 264.384, 78.875 259.476, 80.179 256.247 C 82.453 250.617, 82.510 249.614, 83 206.797 L 83.500 163.094 96.218 182.830 C 110.103 204.379, 115.547 210.647, 123.967 214.782 C 128.815 217.164, 130.676 217.499, 139 217.489 C 157.584 217.469, 161.179 214.209, 187.584 173.433 C 206.631 144.020, 207.317 143.109, 209.348 144.531 C 212.608 146.815, 218.277 146.399, 220.822 143.690 C 222.627 141.768, 223.412 138.368, 225.495 123.440 C 228.660 100.757, 228.692 97.598, 225.777 95.557 C 224.555 94.701, 222.700 94, 221.654 94 C 218.794 94, 185.245 112.873, 182.975 115.759 C 180.073 119.449, 180.497 122.697, 184.390 126.590 L 187.781 129.981 168.140 160.157 C 157.338 176.755, 147.230 191.271, 145.677 192.417 C 141.301 195.646, 134.925 195.261, 131.035 191.533 C 129.332 189.902, 117.848 173.012, 105.514 154 C 93.181 134.988, 81.908 118.323, 80.464 116.967 C 78.342 114.973, 76.803 114.500, 72.439 114.500 C 67.673 114.500, 66.654 114.884, 63.769 117.768 L 60.500 121.037 60 183.268 C 59.445 252.402, 59.724 249.687, 52.701 254.100 C 48.244 256.900, 40.039 256.306, 36.363 252.917 C 30.830 247.815, 31 250.679, 31 162.414 L 31 80.764 33.945 77.409 C 36.666 74.311, 38.989 68.595, 38.989 65 C 38.989 58.517, 33.235 49.832, 27.298 47.351 C 23.207 45.642, 15.404 45.675, 11.500 47.417",
      stroke: "none",
      fill: "#5404ac",
      "fill-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      d: "M 28.806 9.750 C 12.751 17.858, 9.343 19.959, 8.566 22.223 C 6.839 27.261, 8.922 28.987, 28.564 38.791 C 38.712 43.856, 48.111 48, 49.451 48 C 52.879 48, 57.030 44.087, 57.849 40.085 L 58.541 36.701 61.319 39.101 C 62.847 40.420, 78.861 65.562, 96.905 94.972 C 114.950 124.381, 130.835 149.281, 132.207 150.305 C 135.591 152.831, 143.435 152.812, 146.842 150.269 C 148.240 149.225, 164.321 124.103, 182.577 94.443 C 200.833 64.781, 216.978 39.611, 218.454 38.507 C 220.767 36.778, 222.996 36.455, 234.569 36.173 L 248 35.845 247.998 140.173 C 247.997 237.501, 247.880 244.733, 246.248 247.980 C 241.114 258.200, 227.016 258.635, 220.995 248.759 C 219.178 245.780, 219.007 243.691, 219.004 224.405 C 219 204.235, 219.092 203.227, 221.102 201.407 C 233.080 190.568, 224.032 169, 207.508 169 C 201.333 169, 197.216 170.901, 192.807 175.787 C 186.780 182.466, 186.390 191.844, 191.819 199.545 L 194.782 203.749 195.217 226.625 C 195.617 247.615, 195.845 249.994, 197.985 255.500 C 201.238 263.870, 211.172 273.790, 219.500 276.985 C 227.644 280.110, 239.146 280.130, 247.285 277.032 C 255.343 273.965, 263.848 265.906, 267.599 257.782 L 270.500 251.500 270.753 137.500 C 271.003 25.136, 270.978 23.453, 269.013 20.241 C 267.916 18.448, 265.552 16.071, 263.759 14.957 C 260.699 13.056, 259.126 12.949, 238 13.216 L 215.500 13.500 209.248 16.890 C 205.809 18.755, 201.305 22.130, 199.238 24.390 C 197.172 26.651, 182.942 48.897, 167.617 73.827 C 152.292 98.756, 139.405 118.781, 138.979 118.327 C 138.554 117.872, 126.223 97.925, 111.578 74 C 96.933 50.075, 83.322 28.536, 81.330 26.136 C 76.944 20.849, 70.355 16.529, 63.271 14.296 C 58.962 12.938, 58 12.224, 58 10.382 C 58 6.094, 56.061 2.924, 52.538 1.452 C 50.628 0.653, 48.850 0, 48.588 0 C 48.326 0, 39.424 4.387, 28.806 9.750",
      stroke: "none",
      fill: "#fc4c6c",
      "fill-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }